<template>
  <div id="Playlists" class="px-4 py-4">
    <div class="d-flex align-center custom-input-wrapper">
      <v-text-field
          v-model="playlist.playlistName"
          hide-details
          maxlength="25"
          placeholder="New playlist name"
          class="w-auto"
      ></v-text-field>
      <button @click="newPlaylist()" class="action-button action-button__white" :disabled="playlist.playlistName ? false : true">
        <plus-icon />
      </button>
    </div>
    <v-row dense>
      <v-col v-for="(playlist, i) in playlists" :key="i" cols="12">
        <v-lazy>
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pt-3 mt-3 playlist"
              rounded="xl"
              :elevation="hover ? 16 : 2"
              @click.native="selectPlaylist(playlist)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                    ]"
                    >{{ playlist.playlistName }}</v-card-title
                  >
                </div>
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon large>mdi-music-note-outline</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-hover>
        </v-lazy>
        <button
            @click="deletePlaylist(playlist._id, i)"
            class="action-button action-button__danger sm"
        >
          <trash-icon />
        </button>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { deletePlaylist, getPlaylists, createPlaylist } from "@/services/users";
import TrashIcon from "@/components/svg/TrashIcon.vue";
import PlusIcon from "@/components/svg/PlusIcon.vue";

export default {
  name: "playlists",
  components: {PlusIcon, TrashIcon},
  data() {
    return {
      playlist: {
        playlistName: '',
      },
      playlists: [],
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    // playlist() {
    //   return this.playlists.filter(
    //       (el) => el.playlistName === this.$route.params.name
    //   );
    // },
  },
  methods: {
    selectPlaylist: function (playlist) {
      this.$router.push("/playlists/" + playlist.playlistName);
    },
    newPlaylist: function () {
      let self = this;
      let props = {
        playlist: this.playlist,
      };
      this.playlists.push(props.playlist)
      createPlaylist(props,function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
      })
    },
    deletePlaylist: function (playlistId, index) {
      let self = this;
      deletePlaylist(playlistId, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Playlist Deleted", "success");
          self.playlists.splice(index, 1);
        }
      });
    },
  },
  beforeMount() {
    let self = this;
    getPlaylists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.playlists = data.payload.playlists;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.col {
  position: relative;
}
.action-button__danger {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.v-sheet.v-card {
  box-shadow: none !important;
  border: 1px solid var(--border-color);
  background-color: var(--artist-background);
  cursor: pointer;
  border-radius: 8px !important;
  transition: 0.15s;

  &:hover {
    box-shadow: 1px 1px 5px 0px rgba(var(--box-shadow), 0.3) !important;
  }
}
</style>
