<template>
  <v-menu
      offset-x
      :close-on-content-click="false"
      v-model="dropdownVisible"
      class="test"
  >
    <!-- Activator Button -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="action-button action-button__primary sm"
        :title="$t('add-to-playlist')"
      >
        <playlist-icon />
      </v-btn>
    </template>

    <!-- Dropdown Menu Content -->
    <v-list>
      <v-list-item>
        <button v-if="!showAddPlaylist" @click="showAddPlaylist = true" class="playlist-button add mt-0">
          <plus-icon /> {{ $t('create-playlist') }}
        </button>
        <div v-if="showAddPlaylist" class="w-100">
          <form class="d-flex align-center custom-input-wrapper" action="" @submit.prevent>
            <v-text-field
              v-model="playlist.playlistName"
              hide-details
              maxlength="25"
              placeholder="New playlist name"
              class="w-auto"
            ></v-text-field>
            <button
              @click="newPlaylist()"
              type="submit"
              class="action-button action-button__white ml-1"
              :disabled="playlist.playlistName ? false : true"
            >
              <plus-icon />
            </button>
          </form>
        </div>
      </v-list-item>
      <v-list-item
        v-for="(playlist, index) in playlists"
        :key="`${playlist}-${index}`"
      >
        <v-list-item-title>
          <button @click="addSong(playlist._id, songId)" class="playlist-button">
            {{ playlist.playlistName }}
          </button>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import PlaylistIcon from "@/components/svg/PlaylistIcon.vue";
import {createPlaylist, getPlaylists} from "@/services/users";
import PlusIcon from "@/components/svg/PlusIcon.vue";

export default {
  components: {PlusIcon, PlaylistIcon},
  props: {
    songId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      dropdownVisible: false,
      playlist: {
        playlistName: '',
      },
      playlists: [],
      inPlaylist: false,
      showAddPlaylist: false
    };
  },
  methods: {
    addSong(playlistId, songId) {
      let self = this;
      self.$emit('add-song-to-playlist', playlistId, songId)
      this.dropdownVisible = false
    },
    newPlaylist: function () {
      let self = this;
      let props = {
        playlist: this.playlist,
      };
      this.playlists.push(props.playlist)
      createPlaylist(props,function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
        getPlaylists(function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.playlists = data.payload.playlists;
          }
        });
      })
    },
  },
  beforeMount() {
    let self = this;
    getPlaylists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.playlists = data.payload.playlists;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.action-button {
  height: 40px !important;
  padding: 0 !important;
  min-width: unset !important;
  box-shadow: none !important;
  &::before {
    display: none !important;
    visibility: hidden !important;
  }
}
.v-list-item {
  padding: 0;
  min-height: unset;
}
.playlist-button {
  padding: 0 12px;
  margin-top: 8px;
  height: 36px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  width: 100%;
  text-align: left;
  background-color: var(--body-background);
  color: var(--text);
  line-height: 1;
  transition: 0.15s;
  &.add {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      height: 16px;
    }
  }
  &:hover {
    background-color: var(--body-background-invert);
  }
}
</style>
