<template>
  <v-app id="karaoke" :data-theme="theme">
    <v-app-bar
      app
      clipped-right
      clipped-left
      v-if="$route.path !== '/user/subscription'"
    >
      <v-row align="center">
        <!--        <v-app-bar-nav-icon class="sidebar-toggle" @click.stop="(drawer = !drawer), (mini = !mini)" />-->
        <button
          class="sidebar-toggle"
          @click.stop="(drawer = !drawer), (mini = !mini)"
        >
          <menu-open />
        </button>
        <div class="app-logo hidden-sm-and-down">
          <img v-if="theme === 'light'" :src="DarkLogo" alt="logo" />
          <img v-if="theme === 'dark'" :src="LightLogo" alt="logo" />
        </div>
        <v-spacer />

        <v-autocomplete
          v-model="select"
          auto-select-first
          :items="searchSongs"
          :loading="isLoading"
          :search-input.sync="search"
          color="white"
          hide-selected
          item-value="_id"
          append-icon="search"
          return-object
          :append-icon-cb="() => {}"
          :placeholder="$t('search')"
          single-line
          hide-details
          no-filter
          :no-data-text="$t('we_have_not_find_anything')"
          hide-no-data
          :class="[
            'normalSearch'
          ]"
        >
          <template v-slot:item="data">
            <v-list-item @click="searchSelected(data)">
              <div class="search-item">
                <div class="search-item__main">
                  <div class="search-item__image">
                    <img :src="'/api/stream/' + data.item.image" :alt="data.item.image">
                  </div>
                  <div class="search-item__info">
                    <p>{{data.item.fullDescription}}</p>
                    <div class="search-item__details">
                      <span>{{data.item.views}} {{$t('views')}}</span>
                      <span>{{$t('published')}} {{data.item.releaseYear}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-spacer />
        <div v-if="isLogged" class="header-profile">
          <router-link class="header" to="/user">
            <user-icon />
          </router-link>
        </div>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :width="210"
      :mini-variant.sync="miniDrawer"
      mini-variant-width="72"
      app
      clipped
      :permanent="checkScreen"
      v-if="$route.path !== '/user/subscription'"
      class="sidebar"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
    >
      <v-list class="menu-main">
        <v-list-item class="app-logo hidden-md-and-up">
          <img
            v-if="theme === 'light'"
            :src="DarkLogo"
            align="center"
            alt="logo"
          />
          <img
            v-if="theme === 'dark'"
            :src="LightLogo"
            align="center"
            alt="logo"
          />
        </v-list-item>
        <v-list-item
          v-for="item in menuList"
          :key="item.text"
          @click="goTo(item.to)"
          link
        >
          <v-list-item-action>
            <component :is="item.icon" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <button @click.stop="drawer = false" class="close-mobile-menu">
          <left-arrow />
        </button>
      </v-list>
      <v-list class="themes-list">
        <v-list-item
          @click="setDarkTheme()"
          :class="{ selected: theme === 'dark' }"
        >
          <v-list-item-action>
            <moon-icon />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Dark mode </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="setLightTheme()"
          :class="{ selected: theme === 'light' }"
        >
          <v-list-item-action>
            <sun-icon />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Light mode </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="languageDialog = true">
          <v-list-item-action>
            <component :is="$i18n.locale + '-icon'" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ activeLanguage }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-dialog
        v-model="languageDialog"
        width="200"
        content-class="language-dialog"
      >
        <div class="language-wrapper">
          <button
            v-for="(locale, i) in locales"
            :key="`locale-${i}`"
            @click="setLanguage(locale), (languageDialog = false)"
            :class="{ active: $i18n.locale === locale.value }"
          >
            <component :is="locale.value + '-icon'" />
            <span>{{ locale.title }}</span>
          </button>
        </div>
      </v-dialog>
      <v-list>
        <v-list-item
          v-if="isLogged"
          @click="signOut"
          @click.prevent
          class="red-text"
        >
          <v-list-item-action>
            <logout-icon />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("sign_out") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!isLogged"
          @click="(authDialog = true), (options.isLoggingIn = true)"
          @click.prevent
          class="green-text"
        >
          <v-list-item-action>
            <login-icon />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("sign_in") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!isLogged"
          @click="(authDialog = true), (options.isLoggingIn = false)"
          @click.prevent
          class="red-text"
        >
          <v-list-item-action>
            <signup-icon />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("actions.register") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-dialog v-model="authDialog" width="300" content-class="auth-dialog">
        <v-card>
          <v-card-text>
            <div class="subheading">
              <template v-if="options.isLoggingIn"
                ><v-card-title>{{ $t("sign_in") }}</v-card-title></template
              >
              <template v-else
                ><v-card-title>{{ $t("actions.register") }}</v-card-title></template
              >
            </div>
            <v-form
              v-if="options.isLoggingIn"
              ref="signInForm"
              class="custom-input-wrapper"
            >
              <v-text-field
                v-model="user.email"
                light="light"
                :label="$t('email')"
                type="email"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                light="light"
                :label="$t('password')"
                type="password"
              ></v-text-field>
              <v-card-subtitle class="forgot-password">
                <a class="mb-2" href="/password/request/reset">{{
                  $t("forgot_password")
                }}</a>
              </v-card-subtitle>
              <button
                class="button button-primary"
                @click="signIn"
                @click.prevent
                type="submit"
              >
                {{ $t("sign_in") }}
              </button>
              <v-card-subtitle class="text-center"
                >{{ $t("do_not_have_an_account") }} <br>
                <a class="mb-2" href="#" @click="signUp" @click.prevent>{{
                  $t("actions.register")
                }}</a>
              </v-card-subtitle>
            </v-form>
            <v-form
              v-else
              ref="signUpForm"
              v-model="isValid"
              class="custom-input-wrapper"
            >
              <v-text-field
                v-model="user.email"
                light="light"
                :label="$t('email')"
                type="email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                light="light"
                :label="$t('password')"
                :rules="passwordRules"
                required
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="repeatPassword"
                light="light"
                :label="$t('confirm_password')"
                :rules="repeatPasswordRules"
                required
                type="password"
              ></v-text-field>
              <button
                type="submit"
                @click="signUp"
                @click.prevent
                class="button button-primary mb-2"
              >
                {{ $t("actions.register") }}
              </button>
              <v-card-subtitle class="text-center"
                >Already have an account? <br>
                <a class="mb-2" href="#" @click="signIn" @click.prevent>{{
                  $t("sign_in")
                }}</a>
              </v-card-subtitle>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
    <v-content>
      <router-view
        @song-selected="onSongSelected"
        @show-login="showLogin"
        @show-snackbar="showSnackbar"
        @add-to-paylist="addSongToPlaylist"
        @add-paylist="addPlaylist"
        @close-video="closeVideo"
      />
      <v-fab-transition>
        <v-btn
          fab
          large
          bottom
          right
          fixed
          class="show-player"
          v-show="song.songName && !playerActive"
          @click="showPlayer"
          :style="hidePlayerToggleButton || songUrl === '' ? 'display: none' : ''"
        >
          <arrow-up />
        </v-btn>
      </v-fab-transition>
      <div
        v-click-outside="{
          handler: hidePlayer,
          include,
        }"
        class="player-wrapper"
        :class="{ show: playerActive }"
      >
        <span class="overlay" />
        <Player
          :file="songUrl"
          :song="song"
          :limit="limit"
          ref="karaokePlayer"
          :playlistId="playlistId"
          @song-loaded="onSongLoaded"
          @text-toggle="onTextToggle"
          @song-finished-event="onSongFinished"
          @closeVideoEvent="stopVideoPage"
          @hide-player="hidePlayer"
        />
      </div>
    </v-content>
    <!--    <v-bottom-sheet hide-overlay scrollable v-model="playerActive" content-class="player-dialog">-->
    <!--      <v-sheet class="text-center" height="100px">-->
    <!--        -->
    <!--      </v-sheet>-->
    <!--    </v-bottom-sheet>-->
    <v-dialog hide-overlay scrollable v-model="textActive" width="600px">
      <v-card height="500px">
        <v-card-title class="justify-center"
          >{{ this.song.artist }} - {{ this.song.songName }}
        </v-card-title>
        <v-card-text>
          <pre class="text-center">{{ this.song.textContent }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      v-model="snackbar.showSnackbar"
      right
      top
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <v-dialog
      content-class="confirm-mail-dialog"
      v-model="mailDialog"
      width="500"
    >
      <v-card>
        <v-card-title>{{ $t("email") }}</v-card-title>
        <v-card-text>
          {{ $t("we_have_sent_link_for_registration_on_email") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button class="button button-primary" @click="mailDialog = false">
            {{ $t("ok") }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!isLogged" class="landing-page-join">
      <p>{{ $t("join_message") }}</p>
      <div class="buttons">
        <button
          @click="(authDialog = true), (options.isLoggingIn = true)"
          @click.prevent
        >
          {{ $t("sign_in") }}
        </button>
        <button
          @click="(authDialog = true), (options.isLoggingIn = false)"
          @click.prevent
        >
          {{ $t("actions.register") }}
        </button>
      </div>
    </div>
  </v-app>
</template>

<script>
import DarkLogo from "@/assets/logo.svg";
import LightLogo from "@/assets/logo-white.svg";
import ArrowUp from "@/components/svg/ArrowUp.vue";
import Player from "@/components/Player.vue";
import Playlist from "@/components/Playlist.vue";
import Videos from "@/components/Videos.vue";
import { getSong, getSongUrl, searchSongs } from "@/services/songs";
import { getVideos, getVideoUrl } from "@/services/videos";
import {
  registerUser,
  loginUser,
  getUser,
  createPasswordResetUrl,
  checkUserSubscription,
} from "@/services/users";
import moment from "moment-timezone";
import HomeIcon from "@/components/svg/HomeIcon.vue";
import AboutUsIcon from "@/components/svg/AboutUsIcon.vue";
import ArtistsIcon from "@/components/svg/ArtistsIcon.vue";
import MoonIcon from "@/components/svg/MoonIcon.vue";
import SunIcon from "@/components/svg/SunIcon.vue";
import UsersIcon from "@/components/svg/UsersIcon.vue";
import SubscriptionIcon from "@/components/svg/SubscriptionIcon.vue";
import PaymentsIcon from "@/components/svg/PaymentsIcon.vue";
import ReferralsIcon from "@/components/svg/ReferralsIcon.vue";
import PlaylistIcon from "@/components/svg/PlaylistIcon.vue";
import UserIcon from "@/components/svg/UserIcon.vue";
import LoginIcon from "@/components/svg/LoginIcon.vue";
import LogoutIcon from "@/components/svg/LogoutIcon.vue";
import SignupIcon from "@/components/svg/SignupIcon.vue";
import EnIcon from "@/components/svg/EnIcon.vue";
import SrIcon from "@/components/svg/SrIcon.vue";
import HrIcon from "@/components/svg/HrIcon.vue";
import SlIcon from "@/components/svg/SlIcon.vue";
import BgIcon from "@/components/svg/BgIcon.vue";
import RuIcon from "@/components/svg/RuIcon.vue";
import RoIcon from "@/components/svg/RoIcon.vue";
import DeIcon from "@/components/svg/DeIcon.vue";
import MenuOpen from "@/components/svg/MenuOpen.vue";
import VideoIcon from "@/components/svg/VideoIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import LeftArrow from "@/components/svg/LeftArrow.vue";
import MakeMoneyIcon from "@/components/svg/MakeMoneyIcon.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Karaoke Studio D - Početna",
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: "Karaoke",
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: "Karaoke Studio D - Početna",
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:title",
          content: "Karaoke Studio D - Početna",
        },
        {
          property: "og:url",
          content: window.location.origin,
        },
        {
          property: "og:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  computed: {
    showName: function () {
      return !!this.$vuetify.breakpoint.mdAndUp;
    },
    hideBuy: function () {
      return !!this.$vuetify.breakpoint.smAndDown;
    },
    expiresDate: function () {
      let userTimezone = moment.tz.guess();
      let momentDate = moment.utc(
        this.loggedUser.expiresAt,
        "YYYYMMDD HH:mm:ss"
      );
      momentDate.tz(userTimezone);
      return momentDate.format("DD.MM.YYYY. HH:mm:ss z");
    },
    logo: function () {
      let host = location.host;
      if (host.includes("karaokesrbija")) {
        return require("./assets/karaokesrbija.png");
      }
      if (host.includes("karaokehrvatska")) {
        return require("./assets/karaokehrvatska.png");
      }
      if (host.includes("karaokeslovenia")) {
        return require("./assets/karaokeslovenia.png");
      }
      if (host.includes("karaokebosna")) {
        return require("./assets/karaokebosna.png");
      }
      return require("./assets/karaokesrbija.png");
    },
    isLogged() {
      return this.$store.state.isLogged;
    },
    refUrl() {
      return (
        window.location.origin +
        `/registration/referrer/${this.loggedUser.refUrl}`
      );
    },
    loggedUser() {
      return this.$store.state.user;
    },
    repeatPasswordRules() {
      return [
        () =>
          this.user.password === this.repeatPassword ||
          "Šifre se ne podudaraju",
        (v) => !!v || "Molim Vas ponovite šifru",
      ];
    },
    menuList() {
      let menuList = [];
      let role = this.$store.state.user.role;
      this.items.forEach(function (item, index) {
        if (item.roles.includes(role) || item.roles.includes("all")) {
          menuList.push(item);
        }
      });
      return menuList;
    },
    checkScreen() {
      return this.screenWidth > 959;
    },
    miniDrawer() {
      if (this.checkScreen) {
        return this.mini;
      }
      return false;
    },
    activeLanguage() {
      if (this.$i18n.locale === "en") return "English";
      if (this.$i18n.locale === "sr") return "Serbian";
      if (this.$i18n.locale === "hr") return "Croatian";
      if (this.$i18n.locale === "sl") return "Slovenian";
      if (this.$i18n.locale === "bg") return "Bulgarian";
      if (this.$i18n.locale === "ru") return "Russian";
      if (this.$i18n.locale === "de") return "German";
      if (this.$i18n.locale === "ro") return "Romanian";
      return "";
    },
  },
  props: {
    source: String,
  },
  components: {
    LeftArrow,
    CloseIcon,
    Player,
    Playlist,
    Videos,
    HomeIcon,
    ArtistsIcon,
    AboutUsIcon,
    MoonIcon,
    SunIcon,
    UsersIcon,
    SubscriptionIcon,
    PaymentsIcon,
    ReferralsIcon,
    PlaylistIcon,
    UserIcon,
    LoginIcon,
    LogoutIcon,
    SignupIcon,
    EnIcon,
    SrIcon,
    HrIcon,
    SlIcon,
    BgIcon,
    RuIcon,
    DeIcon,
    RoIcon,
    MenuOpen,
    ArrowUp,
    VideoIcon,
    MakeMoneyIcon
  },
  data: () => ({
    locales: [
      {
        value: "en",
        title: "English",
      },
      {
        value: "sr",
        title: "Serbian",
      },
      {
        value: "hr",
        title: "Croatian",
      },
      {
        value: "sl",
        title: "Slovenian",
      },
      {
        value: "ro",
        title: "Romanian",
      },
      {
        value: "bg",
        title: "Bulgarian",
      },
      {
        value: "ru",
        title: "Russian",
      },
      {
        value: "de",
        title: "German",
      },
    ],
    DarkLogo,
    LightLogo,
    theme: "light",
    authDialog: false,
    languageDialog: false,
    loginDialog: false,
    registerDialog: false,
    hidePlayerToggleButton: false,
    snackbar: {
      showSnackbar: false,
      color: "success",
      message: "This is snackbar",
    },
    mailDialog: false,
    shouldShowLogin: false,
    emailRules: [
      (v) => !!v || "Email polje je neophodno za registraciju",
      (v) => /.+@.+\..+/.test(v) || "Email mora biti validan",
    ],
    passwordRules: [
      (v) => !!v || "Šifra je neophodna za registraciju",
      (v) =>
        /^(?=[a-zA-Z0-9#@$?\!]{5,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*/.test(
          v
        ) ||
        "Šifra mora da sadrži jedno veliko jedno malo slovo i bar jedan broj",
    ],
    isLoading: false,
    isValid: true,
    search: null,
    select: null,
    searchSongs: [],
    playerActive: false,
    forcePause: false,
    textActive: false,
    drawer: window.innerWidth >= 960,
    mini: window.innerWidth >= 960,
    screenWidth: window.innerWidth,
    songUrl: "",
    playlistShow: false,
    limit: false,
    goDark: true,
    playlistId: null,
    song: {},
    items: [
      {
        icon: "home-icon",
        text: "page.home.name",
        to: "/",
        roles: ["all"]
      },
      {
        icon: "artists-icon",
        text: "page.artists.name",
        to: "/artists",
        roles: ["all"],
      },
      {
        icon: "video-icon",
        text: "page.videos.name",
        to: "/videos",
          roles: ["all"],
      },
      {
        icon: "playlist-icon",
        text: "page.playlists.name",
        to: "/playlists",
        roles: ["all"],
      },
      {
        icon: "about-us-icon",
        text: "page.about.name",
        to: "/about",
        roles: ["all"],
      },
      {
        icon: "users-icon",
        text: "page.users.name",
        to: "/users",
        roles: ["legatus"],
      },
      {
        icon: "subscription-icon",
        text: "page.subscription.name",
        to: "/admin/subscription",
        roles: ["legatus"],
      },
      {
        icon: "payments-icon",
        text: "page.payments.name",
        to: "/admin/payments",
        roles: ["legatus"],
      },
      {
        icon: "referrals-icon",
        text: "page.referrals.name",
        to: "/admin/referrals",
        roles: ["legatus"],
      },
      {
        icon: "make-money-icon",
        text: "page.make_money.name",
        to: "/make-money",
        roles: ["all"],
      },
    ],
    user: {
      name: "",
      email: "",
      password: "",
    },
    repeatPassword: "",
    options: {
      isLoggingIn: true,
    },
    passwordResetDialog: false,
    video: undefined,
    // videoFile: null,
    videoLoaded: false,
    videoPlaying: false,
  }),
  methods: {
    goTo(route) {
      if (route !== '/videos' && route !== '/playlists') {
        this.$router.push(route)
      } else {
        if (this.isLogged) {
          this.$router.push(route)
        } else {
          this.authDialog = true
        }
      }
    },
    stopVideoPage() {
      this.closeVideo()
    },
    closeVideo() {
      this.$refs.Videos.stop()
    },
    hidePlayer() {
      this.playerActive = false;
    },
    include() {
      return [document.querySelector(".show-player")];
    },
    showPlayer() {
      this.playerActive = true;
    },
    signIn: function () {
      if (this.options.isLoggingIn) {
        let props = {
          user: this.user,
        };
        let self = this;
        loginUser(props, function (data) {
          if (!data.error) {
            localStorage.setItem("karaoke-user-token", data.payload.token);
            checkUserSubscription(function (data) {
              if (data.error) {
                if (data.payload.data.error_code === 2) {
                  self.showSnackbar(data.payload.data.status, "error");
                  self.$router.push("/user/subscription");
                } else {
                  self.showSnackbar(data.payload.data.status, "error");
                  self.$store.state.isLogged = false;
                  localStorage.removeItem("karaoke-user-token");
                  self.$router.push("/");
                }
              } else {
                getUser(function (data) {
                  if (data.error) {
                    self.showSnackbar(data.payload.data.status, "error");
                    self.$store.state.isLogged = false;
                    localStorage.removeItem("karaoke-user-token");
                    self.$router.push("/");
                  } else {
                    self.$store.state.isLogged = true;
                    self.$store.state.user = data.payload;
                    self.showSnackbar(
                      `Welcome, ${data.payload.name}!`,
                      "success"
                    );
                    self.$refs.signInForm.reset();
                    self.$refs.signInForm.resetValidation();
                    self.authDialog = false;
                  }
                });
              }
            });
          } else {
            self.showSnackbar(data.payload.data.status, "error");
          }
        });
      } else {
        this.options.isLoggingIn = !this.options.isLoggingIn;
        this.$refs.signUpForm.reset();
        this.$refs.signUpForm.resetValidation();
      }
    },
    signUp: function () {
      if (this.options.isLoggingIn) {
        this.options.isLoggingIn = !this.options.isLoggingIn;
      } else if (this.isValid) {
        let self = this;
        let payload = {
          user: this.user,
          hostName: location.origin,
        };
        registerUser(payload, function (data) {
          if (data.error) {
            self.showSnackbar(data.payload.data.status, "error");
          } else {
            self.showSnackbar(
              "Successfully registered please check your email for verification!",
              "success"
            );
            self.mailDialog = true;
            self.$refs.signUpForm.reset();
            self.$refs.signUpForm.resetValidation();
            self.shouldShowLogin = false;
            self.authDialog = false;
          }
        });
      } else {
        this.$refs.signUpForm.validate();
      }
    },
    showLogin: function () {
      this.shouldShowLogin = true;
    },
    signOut: function () {
      this.$store.state.isLogged = false;
      localStorage.removeItem("karaoke-user-token");
      this.$router.push("/");
      this.showSnackbar("Signed out!", "success");
    },
    showSnackbar: function (message, color) {
      this.snackbar.color = color;
      this.snackbar.message = message;
      this.snackbar.showSnackbar = true;
    },
    onSongSelected: function (data) {
      this.songUrl = data.songUrl;
      this.song = data.song;
      this.limit = data.limit;
      this.playlistId = data.playlistId;
      this.playerActive = true;
      this.$refs.karaokePlayer.modifications.pitch = "0";
      this.$refs.karaokePlayer.processing = false;
    },
    onSongFinished: function (data) {
      if (data.index || data.index === 0) {
        const nextSongIndex = data.index + 1;
        const nextSong = this.$refs.Playlist.playlist.songs[nextSongIndex];
        if (nextSong) {
          this.playNextSong(nextSong, nextSongIndex);
          this.$refs.karaokePlayer.modifications.pitch = "0";
        }
      }
    },
    addSongToPlaylist: function (data) {
      this.$refs.Playlist.addSong(data.song);
    },
    addPlaylist: function (data) {
      this.$refs.Playlist.addPlaylist(data.playlist);
    },
    onTextToggle: function (data) {
      this.textActive = !this.textActive;
    },
    onSongLoaded: function (data) {
      this.$refs.karaokePlayer.play();
    },
    editUser: function () {
      this.$router.push("/user/edit");
    },
    searchSelected: function (data) {
      this.$router.push(
        "/artist/" +
          data.item.artist +
          "/" +
          data.item.songName +
          "/" +
          data.item._id
      );
    },
    resetPassword: function () {
      let self = this;
      let payload = {
        user: this.$store.state.user,
        hostName: location.origin,
      };
      createPasswordResetUrl(payload, function (data) {
        if (data.error) {
          self.showSnackbar(data.payload, "error");
        } else {
          self.showSnackbar("We sent you the reset URL email!", "success");
        }
      });
      this.passwordResetDialog = false;
    },
    playNextSong: function (song, index) {
      let self = this;
      getSong(song._id, function (data) {
        if (data.error) {
          self.showSnackbar(data.payload.data.status, "error");
          self.$router.push("/");
        } else {
          self.song = data.payload;
          self.socialTitle = self.song.artist + "-" + self.song.songName;
          self.socialDescription = self.song.textContent;
          self.socialTags = self.song.tags.replace(/ /g, ",").replace(/#/g, "");
          getSongUrl(self.song._id, function (data) {
            if (data.error) {
              self.showSnackbar(data.payload.data.status, "error");
              self.$router.push("/");
            } else {
              self.onSongSelected({
                songUrl: data.payload.url,
                song: self.song,
                limit: data.payload.limit,
                playlistId: index,
              });
            }
          });
        }
      });
    },
    onBlurEventFunction: function () {
      if (this.$refs.karaokePlayer) {
        this.$refs.karaokePlayer.playing = false;
      }
    },
    onFocusEventFunction: function () {
      if (this.$refs.karaokePlayer) {
        this.$refs.karaokePlayer.playing = true;
      }
    },
    toggleRefCopy: function () {
      let textToCopy = this.$refs.copyRefUrl.$el.querySelector("input");
      this.showSnackbar("Url kopiran!", "info");
      textToCopy.select();
      document.execCommand("copy");
    },
    checkWindowWidth() {
      this.screenWidth = window.innerWidth;
    },
    setLightTheme() {
      localStorage.setItem("theme", "light");
      this.theme = "light";
    },
    setDarkTheme() {
      localStorage.setItem("theme", "dark");
      this.theme = "dark";
    },
    setLanguage(val) {
      localStorage.setItem("language", val.value);
      this.$i18n.locale = val.value
    },
    getTheme() {
      if (localStorage.getItem("theme")) {
        const selectedTheme = localStorage.getItem("theme");
        this.theme = selectedTheme;
      } else {
        localStorage.setItem("theme", "light");
        const selectedTheme = localStorage.getItem("theme");
        this.theme = selectedTheme;
      }
    },
    getLanguage() {
      if (localStorage.getItem("language")) {
        const selectedLanguage = localStorage.getItem("language");
        this.$i18n.locale = selectedLanguage;
      } else {
        localStorage.setItem("language", "en");
        const selectedLanguage = localStorage.getItem("language");
        this.$i18n.locale = selectedLanguage;
      }
    },
    // video methods
    toggleFullScreen() {
      const video = this.$refs.videoPlayer;
      if (video !== null) {
        if (document.fullscreenElement === null) {
          video.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    onLeavePIP() {
      const video = this.$refs.videoPlayer;
      if (document.pictureInPictureElement === video) {
        document.exitPictureInPicture();
      }
    },
    // playVideo: function () {
    //   this.video.play();
    // },
    play: function () {
      this.playing = true;
      this.audio.play();
    },
    stop: function () {
      this.playing = false;
    },
    stopVideo: function () {
      this.videoPlaying = false;
      this.video.currentTime = 0;
    },
    turnOffVideo() {
      const video = this.$refs.videoPlayer;
      video.src = '';
      this.onLeavePIP();
    },
    videoToggle() {
      this.stop();
      if (this.videoPlaying) {
        const self = this;
        self.stopVideo();
        self.turnOffVideo()
        getVideoUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      } else {
        const self = this;
        getVideoUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      }
    },
    playVideo(videoId) {
      this.videoToggle(videoId)
    }
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.searchSongs = [];
      // Lazily load input items
      let self = this;
      searchSongs(val, function (data) {
        if (!data.error) {
          data.payload.forEach((item) => {
            item.fullDescription = item.artist + " - " + item.songName;
            self.searchSongs.push(item);
          });
          self.searchSongs = data.payload;
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }
      });
    },
    $route: function (value) {
      if (value.query.showLogin) {
        this.showLogin();
      }
      if (value.path.includes('videos')) {
        this.hidePlayerToggleButton = true;
        this.playerActive = false
        this.songUrl = ""
      } else {
        this.hidePlayerToggleButton = false;
      }
    },
    videoPlaying(value) {
      if (value) {
        return this.video.play();
      }
      this.video.pause();
    },
  },
  mounted() {
    this.getTheme();
    this.getLanguage();
    this.$refs.videoPlayer.addEventListener('leavepictureinpicture', this.onLeavePIP);
    this.video = this.$el.querySelectorAll("video")[0];
    this.video.addEventListener("pause", () => {
      this.videoPlaying = false;
    });
    this.video.addEventListener("play", () => {
      this.videoPlaying = true;
    });
  },
  created: function () {
    // window.addEventListener("blur", this.onBlurEventFunction);
    // window.addEventListener("focus", this.onFocusEventFunction);
    window.addEventListener("resize", this.checkWindowWidth);
  },
  beforeDestroy() {
    this.$refs.videoPlayer.removeEventListener('leavepictureinpicture', this.onLeavePIP);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkWindowWidth);
  },
};
</script>
<style lang="scss" scoped>
pre {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: Roboto, sans-serif;
  pointer-events: auto;
  box-sizing: inherit;
  background-repeat: no-repeat;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 24px 20px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
}
.smallSearch {
  max-width: 52%;
}
.normalSearch {
  max-width: 100%;
}
</style>
